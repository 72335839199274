@font-face {
  font-family: 'Aktifo A';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Aktifo A Medium'), local('AktifoA-Medium'),
    url('../../assets/fonts/AktifoA-Medium.woff2') format('woff2'),
    url('../../assets/fonts/AktifoA-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Aktifo A';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local('Aktifo A Bold'), local('AktifoA-Bold'),
    url('../../assets/fonts/AktifoA-Bold.woff2') format('woff2'),
    url('../../assets/fonts/AktifoA-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Aktifo A';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Aktifo A Book'), local('AktifoA-Book'),
    url('../../assets/fonts/AktifoA-Book.woff2') format('woff2'),
    url('../../assets/fonts/AktifoA-Book.woff') format('woff');
}

@font-face {
  font-family: 'Aktifo A';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Aktifo A Light'), local('AktifoA-Light'),
    url('../../assets/fonts/AktifoA-Light.woff2') format('woff2'),
    url('../../assets/fonts/AktifoA-Light.woff') format('woff');
}
