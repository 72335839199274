@import 'sass/vars';

#page-home .section-awards {
  .bg-orange {
    position: relative;

    &--parallax {
      @extend %cover;

      background-color: #fe4e1c;
      position: fixed;
      z-index: -1;
      transform-origin: 50% 0%;
    }
  }

  .bg-black {
    position: relative;

    &--parallax {
      @extend %cover;

      background-color: black;
      z-index: 1;
      transform-origin: 50% 0%;

      &.appear {
        transform: scaleY(1) !important;
        transition-duration: time(default);
        transition-property: transform;
        transition-timing-function: ease(inout);
      }
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .headline {
    padding: 190px 0;

    @media (max-width: 1200px) {
      padding: 75px 0;
    }

    &-small {
      padding-left: 135px;
      opacity: 0;
      transform: translateX(20px);
      transition-duration: 1.5s;
      transition-property: opacity, transform;
      transition-timing-function: ease(inout);

      @media (max-width: 1200px) {
        padding: 0;
      }

      &.appear {
        opacity: 1;
        transform: translateX(0);
      }

      p {
        color: black;
        font-size: 36px;
        font-weight: 300;
        line-height: 50px;

        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 1.2;
        }
        @media (max-width: 355px) {
          font-size: 24px;
        }
      }
    }

    &-wrapper {
      width: 100%;
      position: relative;

      .headline-title {
        text-align: center;
        position: relative;
        z-index: 1;
        margin-top: 70px;

        &--line {
          overflow: hidden;

          &:not(:first-child) {
            margin-top: -40px;

            @media (max-width: 1200px) {
              margin-top: -30px;
            }
            @media (max-width: 1023px) {
              margin-top: -20px;
            }
            @media (max-width: 767px) {
              margin: 0;
            }
          }

          span {
            color: black;
            font-size: 300px;
            font-weight: 700;
            text-transform: uppercase;
            display: block;
            transform: translateY(285px);
            transition-duration: time(default);
            transition-property: transform;
            transition-timing-function: ease(inout);

            @media (max-width: 1200px) {
              font-size: 250px;
            }
            @media (max-width: 1023px) {
              font-size: 190px;
            }
            @media (max-width: 767px) {
              font-size: 80px;
            }
          }

          &.appear span {
            transform: translateY(0);
          }
        }
      }

      .headline-projects {
        width: 462px;
        height: 462px;
        position: absolute;
        top: 98px;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);

        @media (max-width: 1200px) {
          top: calc(50% - 50px);
          transform: translate(-50%, -50%) scale(0.85);
        }
        @media (max-width: 1023px) {
          top: calc(50% - 40px);
          transform: translate(-50%, -50%) scale(0.72);
        }
        @media (max-width: 767px) {
          top: calc(50% - 20px);
          transform: translate(-50%, -50%) scale(0.32);
        }

        .item {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transform: translate(-120px, 100px);
          transition-duration: time(slow);
          transition-property: opacity, transform;
          transition-timing-function: ease(inout);

          &-image {
            @extend %cover;

            img {
              @extend %cover;
            }
          }
        }

        &.appear .item {
          &.remove {
            z-index: 6;
            opacity: 0;
            transform: translate(25px, -25px);
          }

          &.active {
            z-index: 5;
            opacity: 1;
            transform: translate(0, 0);
          }

          &.next {
            z-index: 4;
            opacity: 1;
            transform: translate(-60px, 45px);
          }

          &.next-2 {
            z-index: 3;
            opacity: 1;
            transform: translate(-90px, 72px);
          }

          &.next-3 {
            z-index: 2;
            opacity: 1;
            transform: translate(-104px, 85px);
          }
        }
      }
    }

    &-cta {
      margin-top: 70px;
      padding-left: 135px;

      @media (max-width: 1200px) {
        padding-left: 0;
      }
      @media (max-width: 1023px) {
        margin-top: 40px;
      }

      &--title {
        color: black;
        font-size: 36px;
        font-weight: 300;
        line-height: 50px;
        opacity: 0;
        transform: translateX(20px);
        transition-duration: 1.5s;
        transition-property: opacity, transform;
        transition-timing-function: ease(inout);

        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 1.2;
        }
        @media (max-width: 355px) {
          font-size: 24px;
        }

        &.appear {
          opacity: 1;
          transform: translateX(0);
        }
      }

      &--button {
        margin-top: 15px;
        opacity: 0;
        transform: translateX(20px);
        transition-duration: 1.5s;
        transition-property: opacity, transform;
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateX(0);
        }

        a {
          display: flex;
          align-items: center;

          span {
            color: black;
            font-size: 24px;
            font-weight: 500;
            margin-right: 35px;

            @media (max-width: 767px) {
              font-size: 21px;
              line-height: 1.2;
              margin-right: 15px;
            }
            @media (max-width: 355px) {
              font-size: 18px;
            }
          }

          svg {
            transition-duration: time(slow);
            transition-property: transform;
            transition-timing-function: ease(inout);
          }

          &:hover {
            svg {
              transform: translateX(10px);
            }
          }
        }
      }
    }
  }

  .awards {
    padding: 80px 135px;

    @media (max-width: 1200px) {
      padding: 75px 0;
    }

    &-headline {
      display: inline-block;
      position: relative;

      svg {
        position: absolute;
        top: -2px;
        left: calc(100% - 32px);
        z-index: 2;

        path {
          stroke-dasharray: 260;
          stroke-dashoffset: 260;
          transition-duration: time(default);
          transition-property: stroke-dashoffset;
          transition-timing-function: ease(inout);
        }
      }
    }

    &-title {
      color: white;
      font-size: 27px;
      font-weight: 700;
      line-height: 27px;
      text-transform: uppercase;
      margin-bottom: 50px;
      opacity: 0;
      transform: translateX(20px);
      transition-duration: 1.5s;
      transition-property: opacity, transform;
      transition-timing-function: ease(inout);

      @media (max-width: 767px) {
        font-size: 24px;
      }
      @media (max-width: 355px) {
        font-size: 21px;
      }
    }

    &-items {
      width: 100%;
      margin: 0 auto;

      .item {
        position: relative;

        @media (max-width: 767px) {
          padding: 15px 0;
        }

        &:not(:last-child) {
          &::after {
            content: '';
            background-color: #1f1f1f;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scaleX(0);
            transform-origin: 0% 50%;
            transition-duration: 1.5s;
            transition-property: transform;
            transition-timing-function: ease(inout);
          }
        }

        .item-link {
          @extend %cover;

          text-indent: -999px;
          z-index: 3;
          overflow: hidden;
        }

        .item-title {
          font-size: 32px;
          font-weight: 300;
          padding: 30px 0;
          opacity: 0;
          transform: translateX(20px);
          transition-duration: 1.5s;
          transition-property: opacity, transform;
          transition-timing-function: ease(inout);

          @media (max-width: 767px) {
            font-size: 24px;
            line-height: 1.2;
          }
          @media (max-width: 355px) {
            font-size: 21px;
          }

          span {
            color: white;
            transition-duration: time(default);
            transition-property: color;
            transition-timing-function: ease(inout);
          }
        }

        @media (min-width: 1024px) {
          &:hover .item-title span {
            color: #fe4e1c;
          }
        }
      }
    }

    &.appear {
      .awards-headline {
        &.appear svg path {
          stroke-dashoffset: 0;
          transition-delay: 0.3s;
        }
      }

      .awards-title {
        opacity: 1;
        transform: translateX(0);
      }

      .awards-items {
        .item {
          &::after {
            transform: scaleX(1);
          }

          .item-title {
            opacity: 1;
            transform: translateX(0);
          }

          @for $i from 0 to 4 {
            &:nth-child(#{$i}) {
              &::after {
                transition-delay: 0.1s * $i + 0.15s;
              }

              .item-title {
                transition-delay: 0.1s * $i + 0.15s;
              }
            }
          }
        }
      }
    }
  }
}
