@import 'sass/vars';

#main-footer {
  background-color: black;
  padding: 135px 0 100px;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  [appear] {
    opacity: 0;
    transform: translateX(20px);
    transition-duration: 1.5s;
    transition-property: opacity, transform;
    transition-timing-function: ease(inout);

    &.appear {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .background {
    width: 500px;
    height: 504px;
    position: absolute;
    bottom: -90px;
    left: 0;
    z-index: -1;
    pointer-events: none;
    user-select: none;

    @media (max-width: 1200px) {
      left: 0;
    }
    @media (max-width: 1023px) {
      display: none;
    }
  }

  .flex-wrapper {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    &.wrapper-1 {
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 70px;

      @media (max-width: 1200px) {
        align-items: flex-start;
        padding: 0;
      }
    }

    &.wrapper-2 {
      flex-direction: column;
      align-items: flex-end;
      padding-right: 115px;

      @media (max-width: 1200px) {
        margin-top: 50px;
        padding: 0;
      }
      @media (max-width: 1023px) {
        align-items: flex-start;
      }
    }

    .left {
      @media (max-width: 1023px) {
        width: 100%;
      }

      &-title {
        color: white;
        font-size: 36px;
        font-weight: 300;

        @media (max-width: 1023px) {
          font-size: 24px;
        }
        @media (max-width: 355px) {
          font-size: 21px;
        }
      }
    }

    .right {
      padding-bottom: 6px;

      @media (max-width: 1023px) {
        width: 100%;
        margin: 30px 0 0;
        padding: 0;
      }

      &-form {
        border-bottom: 2px solid #fe4e1c;
        display: flex;
        align-items: flex-start;
        width: 490px;

        @media (max-width: 1200px) {
          width: 400px;
        }
        @media (max-width: 767px) {
          width: 100%;
        }

        .field-group {
          width: 100%;

          input {
            background-color: transparent;
            color: #b1b1b3;
            font-size: 24px;
            font-weight: 300;
            line-height: 34px;
            border: none;
            width: 100%;
            padding: 0 30px 30px 0;

            @media (max-width: 767px) {
              font-size: 16px;
              padding: 0 30px 6px 0;
            }
          }
        }

        .form-submit {
          flex-shrink: 0;

          button {
            span {
              color: #b1b1b3;
              font-size: 24px;
              font-weight: 700;
              line-height: 34px;
              transition-duration: time(default);
              transition-property: color;
              transition-timing-function: ease(inout);

              @media (max-width: 767px) {
                font-size: 16px;
              }
            }

            &:hover span {
              color: #fe4e1c;
            }
          }
        }
      }
    }

    .scroll-top {
      margin-top: 135px;

      @media (max-width: 1023px) {
        display: none;
      }

      button {
        display: flex;
        align-items: center;

        span {
          color: #b1b1b3;
          font-size: 24px;
          font-weight: 600;
          line-height: 34px;
          position: relative;
          bottom: -3px;
          margin-right: 20px;
          transition-duration: time(default);
          transition-property: color;
          transition-timing-function: ease(inout);
        }

        svg path {
          transition-duration: time(default);
          transition-property: fill;
          transition-timing-function: ease(inout);
        }

        &:hover {
          span {
            color: #fe4e1c;
          }

          svg path {
            fill: #fe4e1c;
          }
        }
      }
    }

    .social {
      margin-top: 140px;

      @media (max-width: 1023px) {
        margin: 0;
      }

      ul {
        display: flex;
        align-items: center;

        li {
          margin-right: 40px;

          &:last-child {
            margin-right: 0;
          }

          a {
            display: inline-block;

            svg path {
              transition-duration: time(default);
              transition-property: fill;
              transition-timing-function: ease(inout);
            }

            &:hover svg path {
              fill: #fe4e1c;
            }
          }
        }
      }
    }

    .navigation {
      margin-top: 30px;

      ul {
        display: flex;
        align-items: center;

        li {
          margin-right: 40px;

          &:last-child {
            margin-right: 0;
          }

          a,
          button {
            color: #b1b1b3;
            font-size: 18px;
            font-weight: 400;
            line-height: 15px;
            transition-duration: time(default);
            transition-property: color;
            transition-timing-function: ease(inout);

            &:hover {
              color: #fe4e1c;
            }
          }

          button {
            opacity: 0.4;
            cursor: no-drop;

            @media (max-width: 1023px) {
              display: none;
            }
          }
        }
      }
    }
  }
}

#root-about #main-footer {
  background-color: transparent;
  position: relative;
  z-index: 2;
  margin-top: -120px;
  pointer-events: none;

  @media (max-width: 1200px) {
    margin-top: -50px;
  }

  .flex-wrapper.wrapper-2 {
    pointer-events: initial;
  }
}
