body {
  background-color: white;
  font-family: 'Aktifo A';
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &.not-scrollable {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}

.container {
  width: 100%;
  max-width: calc(100vw - 140px);
  margin: 0 auto;

  @media (max-width: 1300px) {
    max-width: calc(100vw - 100px);
  }

  @media (max-width: 767px) {
    max-width: calc(100vw - 60px);
  }

  // @media (max-width: 1490px) {
  //   padding: 0 25px;
  // }
}
