@import 'sass/vars';

#page-home .section-partners {
  background-color: white;
  padding: 215px 0;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .headline {
    padding: 0 135px;

    @media (max-width: 1200px) {
      padding: 0;
    }

    &-wrapper {
      display: inline-block;
      position: relative;
      opacity: 0;
      transform: translateX(20px);
      transition-duration: 1.5s;
      transition-property: opacity, transform;
      transition-timing-function: ease(inout);

      &.appear {
        opacity: 1;
        transform: translateX(0);

        svg path {
          stroke-dashoffset: 0;
          transition-delay: 0.5s;
        }
      }

      .headline-title {
        color: black;
        font-size: 27px;
        font-weight: 700;
        line-height: 27px;
        text-transform: uppercase;

        @media (max-width: 767px) {
          font-size: 24px;
        }
        @media (max-width: 355px) {
          font-size: 21px;
        }
      }

      svg {
        position: absolute;
        top: -3px;
        right: calc(100% - 38px);
        z-index: 2;

        path {
          stroke-dasharray: 160;
          stroke-dashoffset: 160;
          transition-duration: time(default);
          transition-property: stroke-dashoffset;
          transition-timing-function: ease(inout);
        }
      }
    }

    &-items {
      display: grid;
      margin-top: 65px;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 90px;
      grid-row-gap: 40px;

      @media (max-width: 1023px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
      }

      .item {
        display: flex;
        align-items: center;
        opacity: 0;
        transform: translateX(20px);
        transition-duration: 1.5s;
        transition-property: opacity, transform;
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateX(0);
        }

        &-image {
          width: 100%;

          img {
            max-width: 100%;

            @media (max-width: 1023px) {
              max-width: 80%;
            }
          }
        }
      }
    }
  }
}
