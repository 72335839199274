@import 'sass/vars';

#page-about {
  width: 100vw;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#root-about.appear {
  background-color: black;
}
