@import 'sass/vars';

#page-home .section-video {
  position: relative;
  z-index: 1;
  margin-top: -160px;

  .container {
    display: flex;
    justify-content: center;
  }

  .video {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 1065px;
    height: 1065px;
    position: relative;

    @media (max-width: 1023px) {
      width: 900px;
      height: 900px;
    }

    &-circle {
      width: 100%;
      height: 100%;
      position: absolute;
      transform: scale(0);
      transform-origin: 50% 50%;
    }

    &-image {
      background-color: #b1b1b3;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      overflow: hidden;
      transform: translate(-50%, -50%);

      img {
        @extend %cover;
      }
    }

    &-play {
      width: 205px;
      height: 205px;
      position: relative;
      z-index: 2;

      &--bg {
        background-color: #fe4e1c;
        border-radius: 50%;
        width: 200%;
        height: 200%;
        position: absolute;
        top: -50%;
        left: -50%;
        z-index: 1;
        transform-origin: 50% 50%;
        transition-duration: time(default);
        transition-property: background-color;
        transition-timing-function: ease(inout);
        pointer-events: none;
      }

      &--button {
        @extend %cover;

        text-indent: -999px;
        z-index: 5;
        overflow: hidden;
      }

      &--text {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        span {
          color: white;
          font-size: 24px;
          font-weight: 400;
          line-height: 23px;
          text-transform: uppercase;
        }
      }

      &:hover {
        .video-play--bg {
          background-color: black;
        }
      }
    }
  }
}
