@import 'sass/vars';

#page-project .section-hero {
  padding-top: 235px;

  @media (max-width: 767px) {
    padding-top: 200px;
  }

  .flex-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 150px;

    @media (max-width: 1200px) {
      padding: 0;
    }

    .wrapper {
      &-logo {
        opacity: 0;
        transform: translateY(10px);
        transition-duration: time(default);
        transition-property: opacity, transform;
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
          transform: translateY(0);
        }
      }

      &-flex {
        display: flex;
        justify-content: flex-start;
        margin-top: 85px;

        @media (max-width: 1023px) {
          flex-direction: column;
          margin-top: 50px;
        }

        @keyframes hero_arrow_down {
          0% {
            opacity: 0;
            transform: translateY(-15px);
          }

          50% {
            opacity: 1;
            transform: translateY(0);
          }

          100% {
            opacity: 0;
            transform: translateY(15px);
          }
        }

        .left,
        .right {
          &-arrow {
            margin-top: 25px;
            opacity: 0;
            transform: translateY(-15px);
          }
        }

        .left {
          flex-shrink: 0;
          margin-right: 100px;

          @media (max-width: 1023px) {
            margin: 0 0 25px;
          }

          &-title {
            width: 300px;

            @media (max-width: 767px) {
              max-width: 100%;
            }

            span {
              color: black;
              font-size: 36px;
              font-weight: 700;
              line-height: 1.2;
              display: inline-block;
              overflow: hidden;

              @media (max-width: 1023px) {
                font-size: 28px;
              }
              @media (max-width: 355px) {
                font-size: 24px;
              }

              &:not(:last-child) {
                margin-right: 7px;
              }

              s {
                display: inline-block;
                transform: translateY(40px);
                transition-duration: time(default);
                transition-property: transform;
                transition-timing-function: ease(inout);
              }
            }
          }

          &-arrow {
            @media (max-width: 1023px) {
              display: none;
            }
          }
        }

        .right {
          flex-grow: 1;
          max-width: 650px;
          opacity: 0;
          transition-duration: time(default);
          transition-property: opacity;
          transition-timing-function: ease(inout);

          @media (max-width: 1200px) {
            flex-grow: 1;
            width: auto;
          }
          @media (max-width: 767px) {
            flex-grow: unset;
            width: 100%;
          }

          &-description {
            p {
              color: #616161;
              font-size: 24px;
              font-weight: 300;
              line-height: 34px;

              @media (max-width: 1023px) {
                font-size: 16px;
                line-height: 1.5;
              }
            }
          }

          &-arrow {
            display: none;

            @media (max-width: 1023px) {
              display: block;
            }
          }
        }

        &.appear {
          .left,
          .right {
            &-arrow {
              animation-name: hero_arrow_down;
              animation-duration: 2s;
              animation-iteration-count: infinite;
              animation-timing-function: ease(inout);
              animation-delay: 0.6s;
            }
          }

          .left {
            &-title span {
              @for $i from 0 to 10 {
                s {
                  transform: translateY(0);
                }

                &:nth-child(#{$i}) s {
                  transition-delay: 0.1s * $i;
                }
              }
            }
          }

          .right {
            opacity: 1;
            transition-delay: 0.6s;
          }
        }
      }
    }
  }

  .image {
    width: 100%;
    position: relative;
    margin-top: 85px;
    opacity: 0;
    overflow: hidden;
    transition-duration: time(slow);
    transition-property: opacity;
    transition-timing-function: ease(inout);

    @media (max-width: 1023px) {
      margin-top: 60px;
    }

    &.appear {
      opacity: 1;
    }

    &-block {
      display: block;
      width: 100%;
      position: relative;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
      }
    }
  }
}
