@import 'sass/vars';

#page-home {
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.video-full {
  background-color: fade-out(black, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  transition: opacity time(default) ease(inout),
    visibility time(default) ease(inout);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .video-content {
    background-color: black;
    width: 90vw;
    max-width: 1200px;
    height: 75vh;
    max-height: 680px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);

    @media (max-width: 1023px) {
      width: 696px;
      height: 392px;
    }
    @media (max-width: 767px) {
      width: 300px;
      height: 169px;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
