@import 'sass/vars';

#page-projects .section-laboratory {
  background-color: black;
  border-bottom: 1px solid #b1b1b3;
  padding: 100px 0 140px;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .headline {
    display: inline-block;
    position: relative;
    padding-left: 85px;

    @media (max-width: 1200px) {
      padding: 0;
    }

    &-title {
      color: white;
      font-size: 27px;
      font-weight: 700;
      line-height: 27px;
      text-transform: uppercase;
      opacity: 0;
      transform: translateX(10px);
      transition-duration: time(slow);
      transition-property: opacity, transform;
      transition-timing-function: ease(inout);

      @media (max-width: 767px) {
        font-size: 24px;
      }
      @media (max-width: 355px) {
        font-size: 21px;
      }
    }

    svg {
      position: absolute;
      top: -6px;
      left: calc(100% - 7px);
      z-index: 2;

      path {
        stroke-dasharray: 240;
        stroke-dashoffset: 240;
        transition-duration: time(default);
        transition-property: stroke-dashoffset;
        transition-timing-function: ease(inout);
      }
    }

    &.appear {
      .headline-title {
        opacity: 1;
        transform: translateX(0);
      }

      svg path {
        stroke-dashoffset: 0;
        transition-delay: 0.5s;
      }
    }
  }

  .flex-wrapper {
    display: flex;
    margin-top: 80px;

    @media (max-width: 1023px) {
      display: none;
    }

    &.appear {
      .item {
        opacity: 1;
        transform: translateX(0);

        &:nth-child(2) {
          transition-delay: 0.15s;
        }
      }
    }

    .item {
      width: 515px;
      position: relative;
      margin-right: 30px;
      opacity: 0;
      transform: translateX(10px);
      transition-duration: time(slow);
      transition-property: opacity, transform;
      transition-timing-function: ease(inout);

      &-link {
        @extend %cover;

        text-indent: -999px;
        z-index: 3;
        overflow: hidden;
      }

      &-video {
        border-radius: 5px;
        width: 100%;
        height: 660px;
        position: relative;
        overflow: hidden;

        &--image {
          width: 100%;
          height: 100%;
          overflow: hidden;
          transform: scale(1);
          transition-duration: time(default);
          transition-property: transform;
          transition-timing-function: ease(inout);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &--play {
          background-color: #674ae5;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 150px;
          height: 150px;
          position: absolute;
          top: 50%;
          left: 50%;
          padding-left: 12px;
          transform: translate(-50%, -50%);
          transition-duration: time(default);
          transition-property: transform;
          transition-timing-function: ease(inout);
        }
      }

      &-content {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;

        &--title {
          color: white;
          font-size: 24px;
          line-height: 23px;
        }

        &--arrow {
          button {
            svg path {
              fill: white;
            }
          }
        }
      }

      &:hover {
        .item-video {
          &--image {
            transform: scale(1.05);
          }

          &--play {
            transform: translate(-50%, -50%) scale(0.9);
          }
        }
      }
    }
  }
}
