@import 'sass/vars';

.section-services {
  position: relative;
  padding: 175px 0 270px;
  overflow: hidden;

  @media (max-width: 1023px) {
    display: none;
    padding: 125px 0;
  }

  .images {
    border-radius: 5px;
    width: 400px;
    height: 556px;
    position: fixed;
    top: -278px;
    left: -200px;
    z-index: 5;
    overflow: hidden;
    pointer-events: none;

    .image {
      @extend %cover;

      z-index: 1;
      opacity: 0;
      transition-duration: time(fast);
      transition-property: opacity;
      transition-timing-function: ease(inout);

      img {
        @extend %cover;
      }

      &.active {
        z-index: 2;
        opacity: 1;
      }
    }
  }

  .headline {
    display: inline-block;
    position: relative;
    padding-left: 85px;

    @media (max-width: 1200px) {
      padding: 0;
    }

    &-title {
      color: black;
      font-size: 27px;
      font-weight: 700;
      opacity: 0;
      transform: translateX(10px);
      transition-duration: time(slow);
      transition-property: opacity, transform;
      transition-timing-function: ease(inout);

      @media (max-width: 767px) {
        font-size: 24px;
      }
      @media (max-width: 355px) {
        font-size: 21px;
      }
    }

    svg {
      position: absolute;
      top: -12px;
      right: -32px;
      z-index: 2;

      path {
        stroke-dasharray: 240;
        stroke-dashoffset: 240;
        transition-duration: time(default);
        transition-property: stroke-dashoffset;
        transition-timing-function: ease(inout);
      }
    }

    &.appear {
      .headline-title {
        opacity: 1;
        transform: translateX(0);
      }

      svg path {
        stroke-dashoffset: 0;
        transition-delay: 0.5s;
      }
    }
  }

  .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 70px;
    padding-left: 85px;
    opacity: 0;
    transition-duration: time(slow);
    transition-property: opacity;
    transition-timing-function: ease(inout);
    pointer-events: none;

    @keyframes services_wrapper {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-4408px);
      }
    }

    &:hover .wrapper {
      // animation-play-state: paused;
    }

    &.appear {
      opacity: 1;

      .wrapper {
        will-change: transform;
        animation-name: services_wrapper;
        animation-duration: 30s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    .wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .item {
        flex-shrink: 0;
        pointer-events: initial;

        &:not(:last-child) {
          margin-right: 70px;
        }

        &-counter {
          color: black;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 65px;
        }

        &-title {
          color: #fe4e1c;
          font-size: 163px;
          font-weight: 700;
          letter-spacing: -0.2;
          transition-duration: time(default);
          transition-property: color;
          transition-timing-function: ease(inout);
        }

        &-description {
          margin-top: 30px;

          p {
            color: black;
            font-size: 18px;
            font-weight: 300;
            line-height: 25px;
          }
        }

        &:hover {
          .item-title {
            color: #674ae5;
          }
        }
      }
    }
  }
}
