@import 'sass/vars';

#page-project .section-related {
  background-color: black;
  border-bottom: 1px solid #b1b1b3;
  padding: 125px 0 140px;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .headline {
    padding: 0 70px;

    @media (max-width: 1200px) {
      padding: 0;
    }

    &-title {
      color: white;
      font-size: 24px;

      @media (max-width: 1023px) {
        font-size: 18px;
      }
    }
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    padding: 0 70px;

    @media (max-width: 1200px) {
      padding: 0;
    }
    @media (max-width: 1023px) {
      flex-direction: column;
    }

    .item {
      width: calc(50% - 12px);
      position: relative;

      @media (max-width: 1023px) {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 45px;
        }
      }

      &-link {
        @extend %cover;

        text-indent: -999px;
        z-index: 3;
        overflow: hidden;
      }

      &-image {
        border-radius: 5px;
        width: 100%;
        height: 365px;
        position: relative;
        overflow: hidden;
        transform-origin: 0% 0%;
        transition-duration: time(default);
        transition-property: transform;
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          height: 300px;
        }

        &--block,
        &--block img {
          @extend %cover;
        }
      }

      &-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 40px;

        @media (max-width: 767px) {
          margin-top: 20px;
        }

        &--title {
          color: white;
          font-size: 24px;
          font-weight: 500;
          margin-right: 30px;

          @media (max-width: 1023px) {
            font-size: 16px;
            margin-right: 15px;
          }
        }

        &--arrow {
          position: relative;
          bottom: -1px;

          svg path {
            fill: white;
          }
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          .item-image {
            transform: rotate(-1.5deg);
          }
        }
      }
    }
  }
}
