@import 'sass/vars';

#main-header {
  width: 100%;
  // position: fixed;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 50;
  padding: 20px 0;
  transition-duration: time(fast);
  transition-property: background-color, transform;
  transition-timing-function: ease(inout);

  @media (max-width: 1023px) {
    top: 40px;
  }

  &.sticky {
    // background-color: black;
    // transform: translateY(-70px);
  }

  &.remove {
    // transform: translateY(-167px);
  }

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      opacity: 0;
      transition-duration: time(default);
      transition-property: color;
      transition-timing-function: ease(inout);

      &.appear {
        opacity: 1;
      }

      a {
        display: inline-block;
        position: relative;

        @keyframes header_logo_1 {
          0% {
            opacity: 0;
            transform: scale(1.5);
          }

          45% {
            opacity: 0;
            transform: scale(1.5);
          }

          50%,
          60% {
            fill: transparent;
            opacity: 1;
            transform: scale(1);
          }

          70%,
          100% {
            opacity: 0;
            transform: scale(1.5);
          }
        }

        @keyframes header_logo_2 {
          0% {
            fill: transparent;
            stroke-dashoffset: 130;
            opacity: 1;
            transform: scale(1);
          }

          45% {
            fill: transparent;
            stroke-dashoffset: 0;
            opacity: 1;
            transform: scale(1);
          }

          50%,
          60% {
            fill: transparent;
            stroke-dashoffset: 0;
            opacity: 0;
            transform: scale(1.5);
          }

          70%,
          100% {
            fill: transparent;
            stroke-dashoffset: 130;
            opacity: 1;
            transform: scale(1);
          }
        }

        svg {
          overflow: visible;

          &:nth-child(1) {
            position: relative;
            z-index: 1;
          }

          &:nth-child(2) {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            path {
              fill: transparent;
              stroke: #fe4e1c;
              stroke-dasharray: 130;
              stroke-dashoffset: 130;
              transform-origin: 50% 50%;
              stroke-width: 2px;
            }
          }
        }
      }

      &.appear a svg {
        &:nth-child(1) {
          // animation-name: header_logo_1;
          // animation-duration: 8s;
          // animation-iteration-count: infinite;
          // animation-timing-function: ease(inout);
        }

        &:nth-child(2) {
          path {
            // animation-name: header_logo_2;
            // animation-duration: 8s;
            // animation-iteration-count: infinite;
            // animation-timing-function: ease(inout);
          }
        }
      }
    }

    .navigation {
      @media (max-width: 1023px) {
        display: none;
      }

      ul {
        display: flex;
        align-items: center;

        li {
          margin-right: 60px;

          &:last-child {
            margin-right: 0;
          }

          button {
            opacity: 0.4;
            cursor: no-drop;
          }

          a,
          button {
            color: #b1b1b3;
            font-size: 24px;
            font-weight: 400;
            display: inline-block;
            position: relative;
            padding: 2px 0;
            overflow: hidden;
            transition-duration: time(sol);
            transition-property: color;
            transition-timing-function: ease(inout);

            span {
              display: inline-block;

              &:nth-child(1) {
                position: relative;
                z-index: 1;
              }

              &:nth-child(2) {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
              }

              s {
                display: inline-block;
                transform: translateY(32px) skew(-40deg);
                transition-duration: time(sol);
                transition-property: transform;
                transition-timing-function: ease(inout);
              }
            }
          }

          &.appear a,
          button {
            span {
              &:nth-child(1) s {
                transform: translateY(0) skew(0);
              }

              s {
                @for $i from 0 to 10 {
                  &:nth-child(#{$i}) {
                    transition-delay: 0.02s * $i;
                  }
                }
              }
            }

            &:hover {
              color: #fe4e1c;

              span {
                &:nth-child(1) {
                  s {
                    transform: translateY(-32px) skew(40deg);
                  }
                }

                &:nth-child(2) {
                  s {
                    transform: translateY(0);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#root-about #main-header .flex-wrapper {
  .logo a svg path {
    fill: black;
    // stroke: black;
  }

  .navigation ul li a {
    color: black;
  }
}
