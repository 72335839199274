@import 'sass/vars';

#page-about .section-about {
  background-color: black;
  padding-top: 60px;

  .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    .left {
      width: 590px;
      margin-right: 120px;

      @media (max-width: 1350px) {
        margin-right: 100px;
      }
      @media (max-width: 1200px) {
        width: 390px;
      }
      @media (max-width: 1023px) {
        width: 100%;
      }

      &-description {
        opacity: 0;
        transition-duration: time(slow);
        transition-property: opacity;
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
        }

        p {
          color: white;
          font-size: 18px;
          font-weight: 300;
          line-height: 25px;

          @media (max-width: 1023px) {
            font-size: 16px;
            line-height: 1.4;
          }
        }
      }

      &-block {
        margin-top: 75px;

        @media (max-width: 1023px) {
          margin-top: 40px;
        }

        &--title {
          color: white;
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          opacity: 0;
          transform: translateX(10px);
          transition-duration: time(slow);
          transition-property: opacity, transform;
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateX(0);
          }
        }

        &--description {
          margin-top: 30px;
          opacity: 0;
          transition-duration: time(slow);
          transition-property: opacity;
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
          }

          p,
          a {
            color: white;
            font-size: 18px;
            font-weight: 300;
            line-height: 25px;

            @media (max-width: 1023px) {
              font-size: 16px;
              line-height: 1.8;
            }
          }

          a {
            transition-duration: time(default);
            transition-property: color;
            transition-timing-function: ease(inout);

            @media (min-width: 1024px) {
              &:hover {
                color: #fe4e1c;
              }
            }
          }
        }
      }

      &-cta {
        width: 390px;
        margin-top: 93px;
        opacity: 0;
        transform: translateX(10px);
        transition-duration: time(slow);
        transition-property: opacity, transform;
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          width: 100%;
          margin-top: 40px;
        }

        &.appear {
          opacity: 1;
          transform: translateX(0);
        }

        a {
          border: 1px solid white;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 58px;
          padding: 1px 25px 0;
          transition-duration: time(default);
          transition-property: border-color, background-color;
          transition-timing-function: ease(inout);

          svg {
            transition-duration: time(default);
            transition-property: transform;
            transition-timing-function: ease(inout);

            path {
              fill: white;
            }
          }

          span {
            color: white;
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
          }

          @media (min-width: 1024px) {
            &:hover {
              background-color: #fe4e1c;
              border-color: #fe4e1c;

              svg {
                transform: translateX(5px);
              }
            }
          }
        }
      }
    }

    .right {
      flex-shrink: 0;
      width: 510px;

      @media (max-width: 1200px) {
        width: 440px;
      }
      @media (max-width: 1023px) {
        width: 100%;
        margin-top: 40px;
      }

      .wrapper {
        &-description {
          margin-bottom: 30px;

          &--line,
          &--links {
            display: flex;
            align-items: center;
            padding: 5px 0;
            overflow: hidden;

            &:not(:last-child) {
              margin-bottom: -7px;
            }

            span,
            a {
              font-size: 36px;
              font-weight: 300;
              line-height: 39px;

              @media (max-width: 1200px) {
                font-size: 28px;
                line-height: 1.2;
              }
              @media (max-width: 1023px) {
                font-size: 20px;
                line-height: 1.2;
              }

              &:not(:last-child) {
                margin-right: 7px;
              }
            }

            > span {
              transform: translateY(45px);
              transition-duration: time(default);
              transition-property: transform;
              transition-timing-function: ease(inout);
            }

            &.appear {
              span,
              a {
                transform: translateY(0);
              }
            }
          }

          &--line {
            span {
              color: white;
            }
          }

          &--links {
            span {
              color: #b3b6b7;
            }

            a {
              color: #b3b6b7;
              position: relative;
              transition-duration: time(fast);
              transition-property: color;
              transition-timing-function: ease(inout);

              &::after {
                content: '';
                background-color: #fe4e1c;
                height: 2px;
                position: absolute;
                right: 0;
                bottom: -2px;
                left: 0;
                z-index: 1;
                transform: scaleX(0);
                transform-origin: 100% 50%;
                transition-duration: time(fast);
                transition-property: transform;
                transition-timing-function: ease(inout);
              }

              @media (min-width: 1024px) {
                &:hover {
                  color: #fe4e1c;

                  &::after {
                    transform: scaleX(1);
                    transform-origin: 0% 50%;
                  }
                }
              }
            }
          }
        }

        @keyframes hero_arrow_down {
          0% {
            opacity: 0;
            transform: translateY(-15px);
          }

          50% {
            opacity: 1;
            transform: translateY(0);
          }

          100% {
            opacity: 0;
            transform: translateY(15px);
          }
        }

        &-arrow {
          opacity: 0;
          transform: translateY(-15px);
        }
      }

      &-partners {
        margin-top: 100px;

        @media (max-width: 1023px) {
          margin-top: 40px;
        }

        &--title {
          color: white;
          font-size: 18px;
          font-weight: 700;
          line-height: 18px;
          text-transform: uppercase;
          opacity: 0;
          transform: translateX(10px);
          transition-duration: time(slow);
          transition-property: opacity, transform;
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateX(0);
          }
        }

        &--items {
          display: grid;
          margin-top: 35px;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 100px;
          grid-row-gap: 40px;

          @media (max-width: 1200px) {
            grid-column-gap: 60px;
          }
          @media (max-width: 1023px) {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;
            grid-row-gap: 30px;
          }

          .item {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transform: translateX(20px);
            transition-duration: 1.5s;
            transition-property: opacity, transform;
            transition-timing-function: ease(inout);

            @media (max-width: 1023px) {
              justify-content: flex-start;
            }

            &.appear {
              opacity: 1;
              transform: translateX(0);
            }
          }
        }
      }

      &-disciplines {
        margin-top: 60px;

        @media (max-width: 1023px) {
          margin-top: 40px;
        }

        &--title {
          color: white;
          font-size: 18px;
          font-weight: 700;
          line-height: 18px;
          text-transform: uppercase;
          opacity: 0;
          transform: translateX(10px);
          transition-duration: time(slow);
          transition-property: opacity, transform;
          transition-timing-function: ease(inout);

          &.appear {
            opacity: 1;
            transform: translateX(0);
          }
        }

        &--items {
          display: grid;
          margin-top: 30px;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 100px;
          grid-row-gap: 5px;

          .item {
            opacity: 0;
            transform: translateX(20px);
            transition-duration: 1.5s;
            transition-property: opacity, transform;
            transition-timing-function: ease(inout);

            &.appear {
              opacity: 1;
              transform: translateX(0);
            }

            &-title {
              color: white;
              font-size: 18px;
              font-weight: 300;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}
