@import 'sass/vars';

#page-home .section-filters {
  background-color: white;
  padding: 0 0 105px;

  @media (max-width: 1200px) {
    padding: 0 0 75px;
  }

  .container {
    @media (max-width: 1200px) {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }

  .wrapper {
    background-color: #ededed;
    border-radius: 26px;
    height: 965px;
    position: relative;
    padding: 70px 85px 0;
    overflow: hidden;

    @media (max-width: 1200px) {
      border-radius: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
      padding: 75px 0 0;
    }

    .headline {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 222px;
      height: 82px;
      position: relative;
      z-index: 1;

      @media (max-width: 1200px) {
        margin: 0 auto;
      }

      &-title {
        color: black;
        font-size: 27px;
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        opacity: 0;
        transition-duration: time(default);
        transition-property: opacity;
        transition-timing-function: ease(inout);

        @media (max-width: 767px) {
          font-size: 24px;
        }
        @media (max-width: 355px) {
          font-size: 21px;
        }
      }

      svg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        path {
          stroke-dasharray: 500;
          stroke-dashoffset: 500;
          transition-duration: time(default);
          transition-property: stroke-dashoffset;
          transition-timing-function: ease(inout);
        }
      }

      &.appear {
        .headline-title {
          opacity: 1;
        }

        svg path {
          stroke-dashoffset: 0;
          transition-delay: 0.5s;
        }
      }
    }

    .phone {
      display: flex;
      justify-content: center;
      width: 100%;
      position: relative;
      z-index: 2;

      @media (max-width: 1200px) {
        width: 85%;
        margin: 0 auto;
        margin-top: 50px;
      }

      &-image {
        position: relative;
        z-index: 2;

        img {
          @media (max-width: 1200px) {
            display: block;
            max-width: 100%;
          }
        }
      }

      &-tabs {
        background-color: black;
        border-radius: 100px 100px 0 0;
        width: 580px;
        height: 825px;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 1;
        overflow: hidden;
        transform: translateX(-50%);

        @media (max-width: 1200px) {
          border-radius: 15vw 15vw 0 0;
          width: 84vw;
          max-width: 580px;
          height: 100%;
        }

        .item {
          @extend %cover;

          z-index: 1;
          opacity: 0;
          transform: scale(1.05);
          transform-origin: 25% 25%;
          transition-duration: time(slow);
          transition-property: opacity, transform;
          transition-timing-function: ease(inout);

          &.active {
            z-index: 2;
            opacity: 1;
            transform: scale(1);
          }

          &-image {
            @extend %cover;

            img {
              @extend %cover;
            }
          }
        }
      }
    }

    .arrow-left,
    .arrow-right {
      background-color: #674ae5;
      border-radius: 50%;
      width: 150px;
      height: 150px;
      position: absolute;
      bottom: 310px;
      z-index: 5;
      transition-duration: time(fast);
      transition-property: background-color;
      transition-timing-function: ease(inout);

      @media (max-width: 1023px) {
        width: 50px;
        height: 50px;
        top: calc(50% + 100px);
        bottom: unset;
        transform: translateY(-50%);
      }

      @media (min-width: 1024px) {
        &:hover {
          background-color: #0000f6;
        }
      }
    }

    .arrow-left {
      left: 85px;

      @media (max-width: 1023px) {
        left: 20px;
      }
      @media (max-width: 767px) {
        left: 0;
      }
    }

    .arrow-right {
      right: 85px;

      @media (max-width: 1023px) {
        right: 20px;
      }
      @media (max-width: 767px) {
        right: 0;
      }
    }
  }

  .options {
    width: 800px;
    height: 56px;
    position: relative;
    margin: 80px auto 0;

    @media (max-width: 1023px) {
      max-width: 100%;
      height: 130px;
      margin-top: 50px;
    }

    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
      }

      &-link {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        margin-right: 140px;
        opacity: 0;
        transform: translateY(10px);
        transition-duration: time(default);
        transition-property: opacity, transform;
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          order: 2;
          margin: 30px 0 0;
        }

        span {
          color: #674ae5;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 1.8px;
          text-transform: uppercase;
          margin-left: 30px;
          transition-duration: time(default);
          transition-property: color;
          transition-timing-function: ease(inout);

          @media (max-width: 767px) {
            font-size: 16px;
            letter-spacing: 1px;
            margin-left: 15px;
          }
        }

        svg {
          transition-duration: time(default);
          transition-property: transform;
          transition-timing-function: ease(inout);

          path {
            transition-duration: time(default);
            transition-property: fill;
            transition-timing-function: ease(inout);
          }
        }

        @media (min-width: 1024px) {
          &:hover {
            span {
              color: black;
            }

            svg {
              transform: rotate(360deg);

              path {
                fill: black;
              }
            }
          }
        }
      }

      &-content {
        width: 335px;
        position: relative;

        @media (max-width: 1023px) {
          text-align: center;
          order: 1;
          width: 100%;
        }

        &::before {
          content: '';
          background-color: #b1b1b3;
          width: 1px;
          height: 79px;
          position: absolute;
          top: 50%;
          left: -80px;
          z-index: 1;
          transform: translateY(-50%);
        }

        &--title {
          color: black;
          font-size: 24px;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 8px;
          opacity: 0;
          transform: translateX(10px);
          transition-duration: time(default);
          transition-property: opacity, transform;
          transition-timing-function: ease(inout);

          @media (max-width: 767px) {
            font-size: 21px;
          }
          @media (max-width: 355px) {
            font-size: 18px;
          }
        }

        &--subtitle {
          color: black;
          font-size: 18px;
          font-weight: 300;
          opacity: 0;
          transform: translateX(10px);
          transition-duration: time(default);
          transition-property: opacity, transform;
          transition-timing-function: ease(inout);

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
      }

      &.active {
        z-index: 2;

        .option-link {
          opacity: 1;
          transform: translateY(0);
          transition-delay: 0.15s;
        }

        .option-content {
          &--title {
            opacity: 1;
            transform: translateX(0);
            transition-delay: 0.3s;
          }

          &--subtitle {
            opacity: 1;
            transform: translateX(0);
            transition-delay: 0.45s;
          }
        }
      }
    }
  }
}
