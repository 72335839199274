@import 'sass/vars';

#page-home .section-hero {
  position: relative;
  z-index: 2;
  padding-top: 325px;

  @media (max-width: 767px) {
    padding-top: 200px;
  }

  .flex-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 1023px) {
      align-items: flex-start;
    }

    .wrapper {
      &-description {
        margin-bottom: 30px;

        &--line,
        &--links {
          display: flex;
          align-items: center;
          padding: 5px 0;
          overflow: hidden;

          &:not(:last-child) {
            margin-bottom: -7px;
          }

          span,
          a {
            font-size: 36px;
            font-weight: 300;
            line-height: 39px;

            @media (max-width: 767px) {
              font-size: 20px;
              line-height: 1.2;
            }

            &:not(:last-child) {
              margin-right: 7px;
            }
          }

          > span {
            transform: translateY(45px);
            transition-duration: time(default);
            transition-property: transform;
            transition-timing-function: ease(inout);
          }
        }

        &--line {
          span {
            color: black;
          }
        }

        &--links {
          span {
            color: #b3b6b7;
          }

          a {
            color: #b3b6b7;
            position: relative;
            transition-duration: time(fast);
            transition-property: color;
            transition-timing-function: ease(inout);

            &::after {
              content: '';
              background-color: #fe4e1c;
              height: 2px;
              position: absolute;
              right: 0;
              bottom: -2px;
              left: 0;
              z-index: 1;
              transform: scaleX(0);
              transform-origin: 100% 50%;
              transition-duration: time(fast);
              transition-property: transform;
              transition-timing-function: ease(inout);
            }

            @media (min-width: 1024px) {
              &:hover {
                color: #fe4e1c;

                &::after {
                  transform: scaleX(1);
                  transform-origin: 0% 50%;
                }
              }
            }
          }
        }
      }

      @keyframes hero_arrow_down {
        0% {
          opacity: 0;
          transform: translateY(-15px);
        }

        50% {
          opacity: 1;
          transform: translateY(0);
        }

        100% {
          opacity: 0;
          transform: translateY(15px);
        }
      }

      &-arrow {
        opacity: 0;
        transform: translateY(-15px);
      }

      &.appear {
        .wrapper-description {
          &--line {
            &:nth-child(1) {
              span,
              a {
                transition-delay: 0.15s;
              }
            }

            &:nth-child(2) {
              span,
              a {
                transition-delay: 0.1s;
              }
            }

            &:nth-child(3) {
              span,
              a {
                transition-delay: 0.05s;
              }
            }
          }

          &--links {
            span,
            a {
              transition-delay: 0s;
            }
          }

          &--line,
          &--links {
            span,
            a {
              transform: translateY(0);
            }
          }
        }

        .wrapper-arrow {
          animation-name: hero_arrow_down;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-timing-function: ease(inout);
          animation-delay: 0.6s;
        }
      }
    }

    .content {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      position: relative;
      margin-top: 50px;

      &-title {
        width: 100%;
        position: relative;

        &--line {
          overflow: hidden;

          &:nth-child(2) {
            margin-top: 40px;

            @media (max-width: 1023px) {
              margin-top: 20px;
            }
          }

          img {
            display: block;
            width: 100%;
            position: relative;
            transform: translateY(100%);
            transition-duration: time(default);
            transition-property: transform;
            transition-timing-function: ease(inout);
            pointer-events: none;
            user-select: none;
          }

          &.appear img {
            transform: translateY(0);
          }
        }
      }

      &-cta {
        display: none;
        width: 130px;
        height: 54px;
        position: absolute;
        right: 62px;
        bottom: 24px;
        opacity: 0;
        transition-delay: 0.7s;
        transition-duration: time(default);
        transition-property: opacity;
        transition-timing-function: ease(inout);

        &::before {
          content: '';
          background-color: black;
          border-radius: 50px;
          width: 56px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          transition-delay: 0.3s;
          transition-duration: time(default);
          transition-property: width;
          transition-timing-function: ease(inout);
        }

        button {
          border-radius: 50px;
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 2;
          opacity: 0;
          transition-duration: time(default);
          transition-property: opacity;
          transition-timing-function: ease(inout);

          span {
            color: white;
            font-size: 24px;
            font-weight: 500;
          }
        }
      }

      &:hover {
        .content-cta {
          opacity: 1;
          transition-delay: 0s;

          &::before {
            width: 100%;
            transition-delay: 0;
          }

          button {
            opacity: 1;
            transition-delay: 0.3s;
          }
        }
      }
    }
  }
}
