@import 'sass/vars';

#page-contact .section-hero {
  min-height: 100vh;
  position: relative;
  padding: 290px 0 125px;

  @media (max-width: 1023px) {
    min-height: 100vh;
  }
  @media (max-width: 767px) {
    padding: 200px 0 75px;
  }

  &::before {
    @extend %cover;

    content: '';
    background-color: black;
    z-index: 1;
    transform: scaleY(0);
  }

  &.appear {
    &::before {
      transform: scaleY(1);
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .flex-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 1023px) {
      align-items: flex-start;
    }

    .wrapper {
      &-description {
        margin-bottom: 30px;

        &--line,
        &--links {
          display: flex;
          align-items: center;
          padding: 5px 0;
          overflow: hidden;

          &:not(:last-child) {
            margin-bottom: -7px;
          }

          span,
          a {
            font-size: 36px;
            font-weight: 300;
            line-height: 39px;

            @media (max-width: 767px) {
              font-size: 20px;
              line-height: 1.2;
            }

            &:not(:last-child) {
              margin-right: 7px;
            }
          }

          > span {
            transform: translateY(45px);
            transition-duration: time(default);
            transition-property: transform;
            transition-timing-function: ease(inout);
          }
        }

        &--line {
          span {
            color: white;
          }
        }

        &--links {
          span {
            color: #b3b6b7;
          }

          a {
            color: #b3b6b7;
            position: relative;
            transition-duration: time(fast);
            transition-property: color;
            transition-timing-function: ease(inout);

            &::after {
              content: '';
              background-color: #fe4e1c;
              height: 2px;
              position: absolute;
              right: 0;
              bottom: -2px;
              left: 0;
              z-index: 1;
              transform: scaleX(0);
              transform-origin: 100% 50%;
              transition-duration: time(fast);
              transition-property: transform;
              transition-timing-function: ease(inout);
            }

            @media (min-width: 1024px) {
              &:hover {
                color: #fe4e1c;

                &::after {
                  transform: scaleX(1);
                  transform-origin: 0% 50%;
                }
              }
            }
          }
        }
      }

      @keyframes hero_arrow_down {
        0% {
          opacity: 0;
          transform: translateY(-15px);
        }

        50% {
          opacity: 1;
          transform: translateY(0);
        }

        100% {
          opacity: 0;
          transform: translateY(15px);
        }
      }

      &-arrow {
        opacity: 0;
        transform: translateY(-15px);

        svg path {
          fill: white;
        }
      }

      &.appear {
        .wrapper-description {
          &--line {
            &:nth-child(1) {
              span,
              a {
                transition-delay: 0.15s;
              }
            }

            &:nth-child(2) {
              span,
              a {
                transition-delay: 0.1s;
              }
            }

            &:nth-child(3) {
              span,
              a {
                transition-delay: 0.05s;
              }
            }
          }

          &--links {
            span,
            a {
              transition-delay: 0s;
            }
          }

          &--line,
          &--links {
            span,
            a {
              transform: translateY(0);
            }
          }
        }

        .wrapper-arrow {
          animation-name: hero_arrow_down;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-timing-function: ease(inout);
          animation-delay: 0.6s;
        }
      }
    }

    .content {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      position: relative;
      margin-top: 50px;

      &-title {
        width: 100%;
        position: relative;

        &--line {
          overflow: hidden;

          &:nth-child(2) {
            margin-top: 40px;
          }

          img {
            display: block;
            width: 100%;
            position: relative;
            transform: translateY(100%);
            transition-duration: time(default);
            transition-property: transform;
            transition-timing-function: ease(inout);
            pointer-events: none;
            user-select: none;
          }

          &.appear img {
            transform: translateY(0);
          }
        }
      }
    }

    .info {
      margin-top: 95px;
      padding-right: 110px;
      opacity: 0;
      transition-duration: time(default);
      transition-property: opacity;
      transition-timing-function: ease(inout);

      @media (max-width: 767px) {
        margin-top: 60px;
      }

      @media (max-width: 1023px) {
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0;
      }
      @media (max-width: 767px) {
        text-align: left;
      }

      &.appear {
        opacity: 1;
      }

      &-content {
        width: 510px;

        @media (max-width: 1023px) {
          width: 100%;
        }

        &--link {
          @media (max-width: 767px) {
            padding: 5px 0;
          }

          a {
            color: white;
            font-size: 36px;
            font-weight: 300;
            line-height: 40px;
            transition-duration: time(default);
            transition-property: color;
            transition-timing-function: ease(inout);

            @media (max-width: 767px) {
              font-size: 20px;
              line-height: 1.2;
            }

            @media (min-width: 1023px) {
              &:hover {
                color: #fe4e1c;
              }
            }
          }
        }
      }
    }
  }
}
