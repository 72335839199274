@import 'sass/vars';

#page-about .section-hero {
  height: 100vh;
  position: relative;
  opacity: 0;

  &.appear {
    opacity: 1;
  }

  .background {
    @extend %cover;

    video,
    img {
      @extend %cover;
    }
  }
}
