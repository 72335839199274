@import 'sass/vars';

#page-home .section-projects {
  position: relative;
  z-index: 2;
  margin-top: -150px;

  @media (max-width: 1023px) {
    padding-bottom: 75px;
  }

  .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .project {
      position: relative;

      &-link {
        @extend %cover;

        text-indent: -999px;
        z-index: 3;
        overflow: hidden;
      }

      &-image {
        border-radius: 5px;
        width: 100%;
        position: relative;
        overflow: hidden;
        transform-origin: 0% 0%;
        transition-duration: time(default);
        transition-property: transform;
        transition-timing-function: ease(inout);

        &--block,
        &--block img {
          @extend %cover;
        }
      }

      &-content {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;

        @media (max-width: 1023px) {
          justify-content: flex-start;
          margin-top: 20px;
        }

        &--title {
          color: black;
          font-size: 24px;
          line-height: 23px;

          @media (max-width: 1023px) {
            font-size: 16px;
            margin-right: 15px;
          }
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          .project-image {
            transform: rotate(-1.5deg);
          }
        }
      }

      &:nth-child(1) {
        width: 100%;
        margin-bottom: 30px;

        .project-image {
          height: 763px;

          @media (max-width: 1023px) {
            height: 450px;
          }
          @media (max-width: 767px) {
            height: 300px;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: calc(50% - 30px);

        @media (max-width: 1023px) {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }

        .project-image {
          height: 645px;

          @media (max-width: 1023px) {
            height: 450px;
          }
          @media (max-width: 767px) {
            height: 300px;
          }
        }
      }
    }
  }

  .more {
    display: flex;
    justify-content: center;
    margin-top: 200px;

    @media (max-width: 767px) {
      margin-top: 80px;
    }

    &-button {
      position: relative;
      opacity: 0;
      transition-duration: time(default);
      transition-property: opacity;
      transition-timing-function: ease(inout);

      @media (max-width: 767px) {
        width: 100%;
      }

      &.appear {
        opacity: 1;
      }

      a {
        background-color: black;
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 90px;
        position: relative;
        z-index: 3;
        padding: 0 100px;
        transition-duration: time(fast);
        transition-property: background-color;
        transition-timing-function: ease(inout);

        @media (max-width: 767px) {
          display: flex;
          width: 100%;
          height: 70px;
          padding: 0;
        }

        span {
          color: white;
          font-size: 24px;
          font-weight: 500;
          transition-duration: time(fast);
          transition-property: color;
          transition-timing-function: ease(inout);

          @media (max-width: 767px) {
            font-size: 18px;
          }
        }

        @media (min-width: 1024px) {
          &:hover {
            background-color: #fe4e1c;

            span {
              color: black;
            }
          }
        }
      }
    }
  }
}
