@import 'sass/vars';

#main-preloader {
  @extend %cover;

  background-color: white;
  position: fixed;
  z-index: 55;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  transition-duration: time(slow);
  transition-property: clip-path;
  transition-timing-function: ease(inout);

  .logo {
    @extend %cover;

    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
  }

  .progress {
    @extend %cover;

    background-color: #fe4e1c;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 2;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    transition-duration: time(default);
    transition-property: clip-path;
    transition-timing-function: ease(inout);
    will-change: clip-path;

    svg path {
      fill: white;
    }
  }

  &.remove {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    will-change: clip-path;
  }
}
