@import 'sass/vars';

#page-project .section-content {
  padding: 135px 0 50px;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .info {
    display: flex;
    justify-content: space-between;
    width: 948px;
    margin: 0 auto 35px;

    @media (max-width: 1200px) {
      max-width: 100%;
      padding: 0;
    }
    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-item {
      width: 33%;

      &:nth-child(1) {
        width: 45%;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 27.5%;
      }

      @media (max-width: 1023px) {
        width: 100% !important;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      &--title {
        color: #616161;
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        opacity: 0;
        transform: translateX(10px);
        transition-duration: time(default);
        transition-property: opacity, transform;
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          font-size: 18px;
          line-height: 1.2;
        }
      }

      &--subtitle {
        color: #616161;
        font-size: 24px;
        font-weight: 300;
        line-height: 34px;
        opacity: 0;
        transform: translateX(10px);
        transition-duration: time(default);
        transition-property: opacity, transform;
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          font-size: 18px;
          line-height: 1.2;
        }
      }

      &.appear {
        .info-item--title {
          opacity: 1;
          transform: translateX(0);
        }

        .info-item--subtitle {
          opacity: 1;
          transform: translateX(0);
          transition-delay: 0.075s;
        }
      }
    }
  }

  .description {
    width: 948px;
    margin: 0 auto;
    opacity: 0;
    transition-duration: time(default);
    transition-property: opacity;
    transition-timing-function: ease(inout);

    @media (max-width: 1200px) {
      max-width: 100%;
    }

    &.appear {
      opacity: 1;
    }

    p {
      color: black;
      font-size: 18px;
      font-weight: 300;
      line-height: 25px;

      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }

  .wrapper {
    margin-top: 95px;

    > * {
      &:not(:last-child) {
        margin-bottom: 45px;
      }
    }

    &-image--full {
      position: relative;
      overflow: hidden;

      &.container .image-block {
        border-radius: 5px;
      }

      .image-block {
        display: block;
        width: 100%;
        position: relative;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
        }
      }
    }

    &-flex {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1023px) {
        flex-direction: column;
      }

      .image {
        width: calc(50% - 20px);
        position: relative;
        overflow: hidden;

        @media (max-width: 1023px) {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 45px;
          }
        }

        &-block {
          border-radius: 5px;
          display: block;
          width: 100%;
          position: relative;
          overflow: hidden;

          img {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
}
