.page-transition {
  .root-page {
    width: 100vw;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before,
    &::after {
      @extend %cover;

      content: '';
      background-repeat: no-repeat;
      background-position: center center;
      position: fixed;
      transition-duration: time(default);
      transition-property: clip-path;
      transition-timing-function: ease(inout);
      pointer-events: none;
      will-change: clip-path;
    }

    &::before {
      @extend %cover;

      background-color: #fe4e1c;
      background-image: url('../../assets/vectors/general/logo-preloader-white.svg');
      z-index: 500;
      clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    }

    &::after {
      @extend %cover;

      background-color: white;
      background-image: url('../../assets/vectors/general/logo-preloader.svg');
      z-index: 501;
      clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    }

    &.transition-enter {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;

      > * {
        visibility: hidden;
      }
    }

    &.transition-enter-active {
      &::before,
      &::after {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        pointer-events: initial;
      }

      &::before {
        transition-delay: 0.3s;
      }

      &::after {
        transition-delay: 0.7s;
      }
    }

    &.transition-enter-done {
      &::before,
      &::after {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        pointer-events: none;
      }

      &::before {
        transition-delay: 0.7s;
      }

      &::after {
        transition-delay: 0.3s;
      }

      > * {
        visibility: unset;
      }
    }

    &.transition-exit {
      z-index: 1;
    }
  }
}
